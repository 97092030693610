.fullscreen .MuiPaper-root {
    background-color: rgba(33,41,52,.85)!important;
}

.fullscreen .MuiPaper-root .slick-list {
    width: 100%!important;
    height: 100%!important;
    transition: height .5s ease;
}

@media (min-width: 1400px) {
    .fullscreen .MuiPaper-root .slick-slider {
        max-width: 66.66%!important;
        max-height: 80%;
    }    
}

@media (max-width: 1399px) {
    .fullscreen .MuiPaper-root .slick-slider {
        max-width: 100%!important;
        max-height: 80%;
    }   
    
    .fullscreen .slick-arrow.slick-next {
        right: 5%;
    }
    
    .fullscreen .slick-arrow.slick-prev {
        left: 5%;
    }
}