.btn-email-share:hover {
    opacity: .8;
    top: -4px;
}

.btn-email-share {
    position: relative;
    background-color: #00748D !important;
    border-color: #00748D;
    color: #fff!important;

    top: 0;
    opacity: 1;
    -moz-transition: opacity 0.2s ease-in, top 0.2s ease-in!important;
    -ms-transition: opacity 0.2s ease-in, top 0.2s ease-in!important;
    -o-transition: opacity 0.2s ease-in, top 0.2s ease-in!important;
    -webkit-transition: opacity 0.2s ease-in, top 0.2s ease-in!important;
    transition: opacity 0.2s ease-in, top 0.2s ease-in!important;
}