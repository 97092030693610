.bg-transparent .navbar-brand{color:#ffffff!important;}
.bg-transparent .dropdown-item, .bg-transparent .nav-link{color:rgba(255,255,255,.5)!important;}
.bg-transparent .dropdown-item:hover, .bg-transparent .navbar-light .navbar-nav .nav-link:focus, .bg-transparent .navbar-light .navbar-nav .nav-link:hover {color:rgba(255,255,255,.75)!important;}

.bg-transparent .navbar-light .navbar-toggler{
    border-color: rgba(255, 255, 255, 0.1);
}

.bg-transparent .navbar-light .navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Dropdown */
.bg-transparent .dropdown-menu {
    background-color: rgba(255,255,255,0.1);
}

.bg-transparent .dropdown-item:focus, .dropdown-item:hover {
    background-color: rgba(255,255,255,0.2);
}