.lazyimagebk {
    background: linear-gradient(to right,#f2f2f2, #e6e6e6 20%, #d9d9d9 10%, #e6e6e6 20%, #f2f2f2);
    background-size: 300% 100%!important;
    background-position: 0% 0%;
    animation: loading 2s ease infinite;
}

@keyframes loading {
	0% {
		background-position: 0% 0%;
	}
    50% {
        background-position: 150% 0%;
    }
    100% {
        background-position: 300% 0%;
    }
}



.lazyimagebkimg {
    transition: .5s ease;
    background-size: cover;
    background-position: center;
}