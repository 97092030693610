.normalGallery .slick-slide:not(slick-current){
    filter: blur(.1em);
    -webkit-filter: blur(.1em);
    opacity: 0.5;
    transition: opacity .5s ease-in-out;
}

.normalGallery .slick-slide.slick-current{
    filter: blur(0em);
    -webkit-filter: blur(0em);
    opacity: 1;
}

.normalGallery .slick-slide:hover {
    cursor: pointer;
}

@media (min-width: 1400px) {
    .normalGallery .slick-arrow.slick-next {
        right: 30%;
    }
    
    .normalGallery .slick-arrow.slick-prev {
        left: 30%;
    }
}


@media (max-width: 1399px) {
    .normalGallery .slick-arrow.slick-next {
        right: 5%;
    }
    
    .normalGallery .slick-arrow.slick-prev {
        left: 5%;
    }
}