@media (min-width: 1400px) {
    .propertycard-3:nth-of-type(3n+1) {
        padding-left: 0;
        padding-right: 2rem;
    }
    .propertycard-3:nth-of-type(3n+2) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .propertycard-3:nth-of-type(3n+3) {
        padding-left: 2rem;
        padding-right: 0;
    }
    .propertycard-2:nth-of-type(3n+1) {
        padding-left: 0;
        padding-right: 2rem;
    }
    .propertycard-2:nth-of-type(3n+2) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .propertycard-2:nth-of-type(3n+3) {
        padding-left: 2rem;
        padding-right: 0;
    }
}

@media (max-width: 1399px) {
    .propertycard-3:nth-of-type(3n+1) {
        padding-left: 0;
        padding-right: 2rem;
    }
    .propertycard-3:nth-of-type(3n+2) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .propertycard-3:nth-of-type(3n+3) {
        padding-left: 2rem;
        padding-right: 0;
    }
    .propertycard-2:nth-of-type(n) {
        padding-left: 0;
        padding-right: 1.5rem;
    }
    .propertycard-2:nth-of-type(2n) {
        padding-left: 1.5rem;
        padding-right: 0;
    }
}

@media (max-width: 1199px) {
    .propertycard-3:nth-of-type(n) {
        padding-left: 0;
        padding-right: 1.5rem;
    }
    .propertycard-3:nth-of-type(2n) {
        padding-left: 1.5rem;
        padding-right: 0;
    }
    .propertycard-2:nth-of-type(n) {
        padding-left: 0;
        padding-right: 1.5rem;
    }
    .propertycard-2:nth-of-type(2n) {
        padding-left: 1.5rem;
        padding-right: 0;
    }
}


@media (max-width: 991px) {
    .propertycard-3:nth-of-type(n) {
        padding-left: 0;
        padding-right: .5rem;
    }
    .propertycard-3:nth-of-type(2n) {
        padding-left: .5rem;
        padding-right: 0;
    }
    .propertycard-2:nth-of-type(n) {
        padding-left: 0;
        padding-right: .5rem;
    }
    .propertycard-2:nth-of-type(2n) {
        padding-left: .5rem;
        padding-right: 0;
    }
}

@media (max-width: 577px) {
    .propertycard-3 {
        padding-left: 0!important;
        padding-right: 0!important;
    }
    .propertycard-2 {
        padding-left: 0!important;
        padding-right: 0!important;
    }
}