
body {
    font-family: 'Roboto'!important;
    font-weight: normal;
    font-style: normal;
     transition: opacity .15s linear;
 }


 img{max-width: 100%;}

 tr:first-of-type td, tr:first-of-type th{border-top: none!important;}

 input, textarea{
    border-radius: 0;
    border: none;
    padding: .375rem .75rem;
 }

 /*BOOSTRAP*/
 .form-control{
    border-radius: 0!important;
    border: none!important;
    padding: .375rem .75rem!important;
 }
 .form-control:focus{box-shadow: none!important;}
 .custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid{box-shadow: none!important;}
 .custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid{box-shadow: none!important;}
 .form-check-input{margin-left: 0!important;margin-top: .15rem;}
 .form-check-label{margin-left: 1.25rem;}

 .bg-primary{background-color: #00748D!important;}
 .bg-secondary{background-color: #F8F8F8!important;}
 .bg-tertiary{background-color: #e6e6e6!important;}
 .bg-secondary-transparent{background-color: rgba(0,0,0,.5)!important;}

 a.bg-secondary:hover {background-color: #e6e6e6!important;}

 .btn{border-radius: 0}
 .btn-primary{background-color: #00748D!important; border-color: #00748D;}
 .btn-primary:hover{background-color: #303E47!important; border-color: #00748D;}
 .btn-primary:focus {box-shadow: 0 0 0 .2rem rgba(48, 62, 71,.5)!important;}


.btn-outline-primary {color: #00748D!important; border-color: #00748D!important;}
.btn-outline-primary:hover {background-color: #00748D!important; border-color: #00748D!important; color: #fff!important;}
.btn-outline-primary:focus, .btn-outline-primary.focus {box-shadow: 0 0 0 0.2rem rgba(0, 116, 141, 0.5);}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {color: #00748D!important;}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {background-color: #00748D!important;border-color: #00748D!important; color: #fff!important;}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {box-shadow: 0 0 0 0.2rem rgba(0, 116, 141, 0.5);}

 .text-primary{color:#00748D!important;}

 .round{border-radius: 50%;}
 .round img{border-radius: 50%;}

 .page-item.active .page-link{background-color: #00748D!important; border-color: #00748D!important;}
 .page-link{color:#00748D;}
 .page-link:hover{color: #00748D;}
 .page-link:focus{box-shadow: 0 0 0 .2rem rgba(48, 62, 71, .25)}

 .card{border-radius: 0!important;}

 .badge{border-radius: 0!important;}
 .badge-primary{background-color: #00748D!important;}

 .nav-link.text-white:hover{color:rgba(255, 255, 255,.9)!important;}
 .text-white.soonavailabe{color:#e6e6e6!important;}
 .dropdown-menu{border-radius: 0!important;border:none!important;}

 .progress-bar{background-color: #00748D!important;}

 /*Extra Bootstrap*/
 .w-20{width:20%; height: max-content;}
 .w-15{width:15%; height: max-content;}
 .w-10{width:10%; height: max-content;}
 .z-1{z-index: 1;}
 .z-2{z-index: 2;}
 .z-5{z-index: 5;}
 .z-50{z-index: 5;}
 .lp-125{left:12.5%;}

 .tag{white-space: nowrap;}
 .link:hover{cursor:pointer; text-transform: none; text-decoration: none;}

 .big{
     font-size: 120%;
     font-weight: 300;
 }

 .card{border:none!important;}
 .card-header{border-bottom:none!important;}

 .bg-dark-transparent{background-color: rgba(33,41,52,.85) !important; z-index: 200;}

 .img-cover{
    width: 100%;
    height: 15rem;
    background-size: cover;
    background-position: center;
 }

 .tag{padding-top: .25rem; padding-bottom: .25rem; padding-left: .5rem; padding-right: .5rem; margin-right: .5rem!important; font-weight: bold;}
 .tag-1{background-color: #007d99!important; color: #ffffff;}
 .tag-3{background-color: #00748d!important; color: #ffffff;}
 .tag-5{background-color: #006880!important; color: #ffffff;}
 .tag-8{background-color: #005366!important; color: #ffffff;}
 .tag-13{background-color: #003e4d!important; color: #ffffff;}
 .tag-26{background-color: #002a33!important; color: #ffffff;}

 .overflow-auto{overflow: auto;}

 .table tfoot th{border-top: 2px solid #dee2e6!important;}

 .bg-primary, .bg-danger {
     color: #fff;
 }
 .bg-outline-primary {
     border: 1px solid #00748D !important;
     color: #00748D !important;
 }

 .bg-secondary-original {
     background-color: #6c757d;
     border: 1px solid #6c757d !important;
     color: #fff !important;
 }

 .bg-outline-secondary-original {
     border: 1px solid #6c757d !important;
     color: #6c757d !important;
 }

 .bg-terciary {background-color: #FCFCFC!important;}

 .bg-outline-danger{
     border: 1px solid #dc3545;
     color: #dc3545;
 }
 /*New classes*/

 .main{min-height: 85vh!important;}
 .b-0{bottom: 0;}
 .b-1{bottom: .25em;}
 .b-2{bottom: .5em;}
 .b-3{bottom: 1em;}
 .b-4{bottom: 1.5em;}
 .t-0{top: 0;}
 .t-1{top: .25em;}
 .t-2{top: .5em;}
 .t-3{top: 1em;}
 .t-4{top: 1.5em;}
 .t-50{top: 50%;}
 .r-0{right: 0;}
 .r-1{right: .25em;}
 .r-2{right: .5em;}
 .r-3{right: 1em;}
 .r-4{right: 1.5em;}
 .l-0{left: 0;}
 .l-1{left: .25em;}
 .l-2{left: .5em;}
 .l-3{left: 1em;}
 .l-4{left: 1.5em;}

 .pl-8{padding-left: 3rem!important;}
 .pr-8{padding-right: 3rem!important;}

 .w-7-5{width: 7.5%;}

 .br-0{border-radius: 0!important;}

 .colp-30{
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
 }

 .offsetp-5{margin-left: 5%;}

 .op-0{opacity: 0.25!important;}

 .bkimage{
     background-position: 50% 50%;
     background-repeat: no-repeat;
     background-size: cover;
     min-height: 40vh;
     opacity: .9;
 }

 .a-primary {
     color: #00748D;
     text-decoration: none;
     background-color:
     transparent;
     -webkit-text-decoration-skip: objects;
 }
 .a-primary:hover {
     color: #303E47;
     text-decoration: underline;
 }

 .op-20 {opacity: .2;}

 section.mainBody>section.bg-secondary{min-height: 80vh;}

 .fadetransition {
    transition: opacity .5s;
    -moz-transition: opacity .5s;
    -webkit-transition: opacity .5s;
 }


 /*Selectize*/
 .selectize-input>input{background-color: #F8F8F8;}
 .selectize-control{border:1px solid #00748D; padding: 0!important;}
 .selectize-input{display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
 .selectize-input>input{min-width:50%!important;}
 .selectize-input>.item{padding: .375rem .75rem; padding-right: 0;}
 .selectize-dropdown{width:100%!important; height: min-content;padding: 0!important;position: absolute; z-index: 5;}
 .selectize-dropdown-content>.option{padding: .375rem .75rem;}
 .selectize-dropdown-content>.option.active{background-color: #e6e6e6;}

 .selectize-dropdown-content>.optgroup>.optgroup-header{padding: .15rem .75rem; text-decoration: underline;}
 .selectize-dropdown-content>.optgroup>.option{padding: .375rem .75rem;}
 .selectize-dropdown-content>.optgroup>.option.active{background-color: #e6e6e6;}

 /*Checkboxes and radios*/

 .containercheckbox {
   display: block;
   position: relative;
   padding-left: 35px;
   margin-bottom: 12px;
   cursor: pointer;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }

 .containercheckbox input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
 }

 .containercheckbox input:disabled, .containercheckbox input[readonly] {opacity:0;}

 .checkmark {
   position: absolute;
   top: 0;
   left: 0;
   height: 25px;
   width: 25px;
   background-color: #F8F8F8;
 }

 .containercheckbox:hover input ~ .checkmark {
   background-color: #e6e6e6;
 }

 .containercheckbox input:checked ~ .checkmark {
   background-color: #00748D;
 }

 .checkmark:after {
   content: "";
   position: absolute;
   display: none;
 }

 .containercheckbox input:checked ~ .checkmark:after {
   display: block;
 }

 .containercheckbox .checkmark:after {
   left: 9px;
   top: 5px;
   width: 5px;
   height: 10px;
   border: solid white;
   border-width: 0 3px 3px 0;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
 }

 .checkmark.round{
     border-radius: 50%!important;
 }

 /*Animation.css*/
 .animated{animation-duration: 1s!important; animation-delay: .25s!important;}
 .animated.slow{animation-duration: 3s!important;}

 /*Open Street Maps*/
 .map{min-height: 30vh;}
 .map-lg{min-height: 40vh;}

 /*Loading gifs*/
 .loadinggif{
     position: fixed;
     width: 100%;
     height: 100%;
     top:0;
     left: 0;
     background-color: rgba(0, 0, 0, 0.3);
     z-index: 500;
 }

 .loadinggif img{
     position: relative;
     top: 45%;
     left: 45%;
     width: 10%;
 }

/* Social Share */
.btn-facebook{background-color: #4267B2; color:#ffffff;}
.btn-facebook:hover{background-color: #375695;}
.btn-twitter{background-color: #1da1f2; color:#ffffff;}
.btn-twitter:hover{background-color: #0d8bd9;}
.btn-linkedin{background-color: #2867b2; color:#ffffff;}
.btn-linkedin:hover{background-color: #215491;}
.btn-pinterest{background-color: #bd081c; color:#ffffff;}
.btn-pinterest:hover{background-color: #930617;}
.btn-whatsapp{background-color: #4ac959; color:#ffffff;}
.btn-whatsapp:hover{background-color: #35b143;}
.btn-messenger{background-color: #0078ff; color:#ffffff;}
.btn-messenger:hover{background-color: #005fcc;}
.btn-telegram{background-color: #0088cc; color: #ffffff;}
.btn-telegram:hover{background-color: #006699;}

/* Responsivity
 {% cycle 'pr-8' 'px-4' 'pl-8'%}
 */

@media (min-width: 1200px) {
    .pl-xl-8{padding-left: 3rem!important;}
    .xl-responsive-showfirst{
        display: flex!important;
    }
    .xl-responsive-showsecond{
        display: none!important;
    }
}

@media (min-width: 1500px) {
    .xl-responsive-showfirst{
        display: none!important;
    }
    .xl-responsive-showsecond{
        display: flex!important;
    }
}

@media (max-width: 1199px) {
    
}

@media (max-width: 991px) {
    .lp-125 {
        left: 0;
    }
}

@media (max-width: 577px) {
    .display-4 {
        font-size: 2.5rem;
    }
}

.x-small {
    font-size: 60%;
}

.font-weight-bold {
    font-weight: 700!important;
}

a {
    color: #00748D;
    text-decoration: none;
}

a:hover {
    color: #303E47;
    text-decoration: underline;
}

.nowrap {
    white-space: nowrap;
}